import React from "react";
import { useGlitch } from "react-powerglitch";
import styled from "styled-components";
import background from "../assets/background.jpg";
import logo from "../assets/logo.svg";
import Footer from "../Footer";
import Section from "../Section/Section";
import { grooveCastItems } from "../utils";
import TicketButton from "./Buttons/TicketButton";
import ContactForm from "./ContactForm/ContactForm";
import Events from "./Events";
import GenreSlider from "./GenreSlider/GenreSlider";
import GrooveCastDisks from "./GooveCast/GrooveCastDisk";
import GrooveCastII from "./GooveCast/GrooveCastII";
import GrooveCastIII from "./GooveCast/GrooveCastIII";
import { Hero } from "./Hero";
import Residents from "./Residents/Residents";
import Social from "./Social/Social";
const LandingPage: React.FC = () => {
  const glitch = useGlitch({
    playMode: "hover",
  });

  return (
    <LandingPageContainer>
      <Hero
        title="We bring the groove"
        typingTexts={["We create", "We rave", "We enjoy"]}
      />
      <GenreSlider></GenreSlider>
      <TicketButton href={"https://avantikollektiv.ticket.io/"}></TicketButton>
      {/* <GrooveCastII></GrooveCastII> */}
      <EventsContainer>
        <Events></Events>
      </EventsContainer>
      <TicketButton href={"https://avantikollektiv.ticket.io/"}></TicketButton>
      <Social />
      <Section title="Residents" content={<Residents />} />
      <GrooveCastIII></GrooveCastIII>
      <GrooveCastII></GrooveCastII>
      <GrooveCastDisks grooveCasts={grooveCastItems}></GrooveCastDisks>
      <ContactForm></ContactForm>
      <div>
        <Logo src={logo} alt="Music Label Logo" ref={glitch.ref} />
      </div>
      <Footer></Footer>
      <GenreSlider></GenreSlider>
    </LandingPageContainer>
  );
};

export default LandingPage;

const LandingPageContainer = styled.div`
  background-image: url(${background});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EventsContainer = styled.div`
  @media (max-width: 600px) {
    max-width: 100%; /* Full width on small screens */
  }
`;

const Logo = styled.img`
  width: 750px;
  height: auto;
  filter: drop-shadow(0 0 1rem purple);
  @media (max-width: 600px) {
    max-width: 100%; /* Full width on small screens */
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    max-width: 80%; /* Smaller width on medium screens */
  }
`;
