import React, { useState, useEffect } from 'react';

interface TypeWriterProps {
  texts: string[];
  delay?: number;
  speed?: number;
}

export const TypeWriter: React.FC<TypeWriterProps> = ({ 
  texts, 
  delay = 2000,
  speed = 100 
}) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const type = () => {
      const fullText = texts[currentTextIndex];
      
      if (!isDeleting) {
        setCurrentText(fullText.substring(0, currentText.length + 1));
        
        if (currentText.length === fullText.length) {
          timeout = setTimeout(() => setIsDeleting(true), delay);
          return;
        }
      } else {
        setCurrentText(fullText.substring(0, currentText.length - 1));
        
        if (currentText.length === 0) {
          setIsDeleting(false);
          setCurrentTextIndex((currentTextIndex + 1) % texts.length);
          return;
        }
      }

      timeout = setTimeout(type, speed);
    };

    timeout = setTimeout(type, speed);

    return () => clearTimeout(timeout);
  }, [currentText, currentTextIndex, isDeleting, texts, delay, speed]);

  return (
    <span className="inline-block">
      {currentText}
      <span className="animate-pulse"></span>
    </span>
  );
}; 