import React from "react";
import { useLocation } from "react-router-dom";
import { Event } from "../../types";
import "./DetailPage.css";

export const DetailPage: React.FC = () => {
  const location = useLocation();
  const detail = location.state as Event;

  const openTicketLink = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="detailContainer">
      <img className="detailImage" src={detail.image} alt="event flyer" />
      <div className="detailTitle">{detail.title}</div>
      <div className="infoContainer">
        <div className="infoDetail venue">📍{detail.location}</div>
        <div className="infoDetail date">📅{detail.date}</div>
      </div>
      <div className="buttonContainer">
        {detail.link && (
          <button
            className="instagramLinkButton"
            onClick={() => openTicketLink(detail.link!)}
          >
            Instagram
          </button>
        )}
        {detail.ticketLink && (
          <button
            className="detailBuyButton"
            onClick={() => openTicketLink(detail.ticketLink!)}
          >
            Buy Tickets
          </button>
        )}
      </div>
      <div className="detailInfoContainer">
        <div className="detailDescription">{detail.description}</div>
      </div>
    </div>
  );
};
