import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fetchEvents } from "../services/eventService";
import { Event } from "../types";
//import { upcomingEvents as upcomingEventsData, pastEvents as pastEventsData } from "../data";

const Events: React.FC = () => {
  const navigate = useNavigate();
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([]);
  const [pastEvents, setPastEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const openInNewTab = (event: Event) => {
    navigate("/details", { state: event });
  };

  useEffect(() => {
    const loadEvents = async () => {
      try {
        setLoading(true);
        const { upcomingEvents, pastEvents } = await fetchEvents();
        setUpcomingEvents(upcomingEvents);
        setPastEvents(pastEvents);
      } catch (err) {
        setError("Failed to load events. Please try again later. 😢");
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, []);

  if (loading) {
    return (
      <EventsContainer>
        <LoadingMessage>Loading amazing events... 🎵</LoadingMessage>
      </EventsContainer>
    );
  }

  if (error) {
    return (
      <EventsContainer>
        <ErrorMessage>{error}</ErrorMessage>
      </EventsContainer>
    );
  }

  return (
    <EventsContainer id="events">
      <h2>Upcoming Events 🎉</h2>
      <hr />
      {upcomingEvents.length === 0 ? (
        <NoEventsMessage>
          No upcoming events at the moment. Stay tuned! ✨
        </NoEventsMessage>
      ) : (
        upcomingEvents.map((event: Event, index: number) => (
          <a
            href={`https://www.event-scanner.com/event/${event.id}`}
            target="_blank"
            rel="noopener noreferrer"
            key={event.title}
          >
            <ImageContainer>
              <EventImage src={event.image} alt={event.title} />
            </ImageContainer>
          </a>
        ))
      )}

      <h2>Past Events 📅</h2>
      <hr />
      {pastEvents.length === 0 ? (
        <NoEventsMessage>No past events to show. 🎪</NoEventsMessage>
      ) : (
        pastEvents.map((event: Event) => (
          <a
            href={`https://www.event-scanner.com/event/${event.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImageContainer key={event.id}>
              <EventImage
                onClick={() => openInNewTab(event)}
                src={event.image}
                alt={event.title}
              />
            </ImageContainer>
          </a>
        ))
      )}
    </EventsContainer>
  );
};

export default Events;

const ImageContainer = styled.div`
  width: 500px;
  max-width: 800px; /* Set a maximum width for desktops */
  margin: 0 auto; /* Center the container */
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    max-width: 100%; /* Full width on small screens */
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    max-width: 80%; /* Smaller width on medium screens */
  }
`;

const EventsContainer = styled.div`
  padding: 2rem;
  color: white;
  h2 {
    margin-bottom: 1rem;
  }
`;

const EventImage = styled.img`
  border-radius: 10px;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 1rem;
  &:hover {
    background: purple;
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 1.5rem;
  padding: 2rem;
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #ff6b6b;
  font-size: 1.2rem;
  padding: 2rem;
`;

const NoEventsMessage = styled.div`
  font-family: "Inter", sans-serif;
  text-align: center;
  font-size: 1.1rem;
  padding: 1rem;
  color: white;
`;
