import React from 'react';
import './GrooveCastII.css';

interface GrooveCastItem {
    id: number;
    title: string;
    cover: string;
    soundcloud: string;
}

const grooveCasts: GrooveCastItem[] = [
    { id: 1, title: 'Season 1', cover: '/images/groovecast_season_II/fef0e87f-cfd1-460a-bb13-f64a04107c16.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 2, title: 'Season 2', cover: '/images/groovecast_season_II/e8765e40-067d-48df-a87d-43b25394888a.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 3, title: 'Season 3', cover: '/images/groovecast_season_II/902304be-7cc9-4cb8-a7a0-efa21a687857.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 4, title: 'Season 3', cover: '/images/groovecast_season_II/e02975c8-948d-4c31-8984-f5b415b9af7e.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 5, title: 'Season 3', cover: '/images/groovecast_season_II/cf45de65-93f3-4bb9-a930-7fe28aa7cfbc.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 6, title: 'Season 3', cover: '/images/groovecast_season_II/70ee2eca-a5bc-41b5-97c1-4ccc808914dc.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 7, title: 'Season 3', cover: '/images/groovecast_season_II/365b9a1c-281c-4747-a347-528454fc373f.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 8, title: 'Season 3', cover: '/images/groovecast_season_II/29f3f225-50b4-4f1c-88a4-33e86bd781d6.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 9, title: 'Season 3', cover: '/images/groovecast_season_II/93d96b50-59bb-44e9-a81c-78302479d096.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 10, title: 'Season 3', cover: '/images/groovecast_season_II/f1f068d6-4412-4d66-bc39-7c8cc661f575.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 11, title: 'Season 3', cover: '/images/groovecast_season_II/db11b297-a089-4a0d-9594-e2c973173e04.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 12, title: 'Season 3', cover: '/images/groovecast_season_II/f7d2bb5d-0543-4ce0-a295-c5e59c3c59b2.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 13, title: 'Season 3', cover: '/images/groovecast_season_II/c4a9e60f-4d11-4816-80c0-d2deb31aab89.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 14, title: 'Season 3', cover: '/images/groovecast_season_II/190bb294-e0ae-4d66-9cb2-3c6de49c3971.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 15, title: 'Season 3', cover: '/images/groovecast_season_II/37a68f82-7d10-4b5a-b97a-11773ad1962e.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 16, title: 'Season 3', cover: '/images/groovecast_season_II/a6ee6341-50a2-4484-94a7-76130f4cdc68.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 17, title: 'Season 3', cover: '/images/groovecast_season_II/fc225517-bc05-42f8-bc0a-d3846f13c3a2.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 18, title: 'Season 3', cover: '/images/groovecast_season_II/dae952be-50aa-4936-aace-578c4f5c53e4.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 19, title: 'Season 3', cover: '/images/groovecast_season_II/cbce0500-e5a9-4210-aeff-5b95156ccb96.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 20, title: 'Season 3', cover: '/images/groovecast_season_II/41b3944e-6333-4dee-abf1-4789a7033e03.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },
    { id: 21, title: 'Season 3', cover: '/images/groovecast_season_II/332aa096-2084-4f6c-aec8-66cc51f3c7eb.png', soundcloud: 'https://soundcloud.com/avantilabelgroup/sets/season-ii' },

];


const GrooveCastII: React.FC = () => {


    return (
        <div className="groovecastII-container">
            <h2 className="groove-title">Groove Cast Season II</h2>
            <div className="groovecast-grid">
                {grooveCasts.map((cast) => (
                    <div key={cast.id} className="groovecast-item" onClick={() => window.open(cast.soundcloud, '_blank')}>
                        <div className="groovecast-cover">
                            <img src={`https://images.vartakt.com${cast.cover}`} alt={cast.title} className="cover-image" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GrooveCastII;