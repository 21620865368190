import React from 'react';
import { TypeWriter } from './TypeWriter';
import './Hero.css'; // Import der neuen CSS-Datei
import { useGlitch } from 'react-powerglitch';

interface HeroProps {
  title: string;
  typingTexts: string[];
}

export const Hero: React.FC<HeroProps> = ({ title, typingTexts }) => {
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const glitch = useGlitch({
    playMode: "hover",
  });

  


  React.useEffect(() => {
    const loadVideo = async () => {
      try {
        if (videoRef.current) {
          const videoUrl = "https://imagebucket.hel1.your-objectstorage.com/headervideos/hero-background-mobile.mp4";
          const connection = (navigator as any).connection;
          const isSlowConnection = connection?.effectiveType === '3g' || connection?.saveData;
          
          if (isSlowConnection) {
            return;
          }

          videoRef.current.src = videoUrl;
        }
      } catch (error) {
        console.error('Error loading video:', error);
      }
    };

    loadVideo();
  }, []);

  return (
    <div className="hero-container">
      {/* Loading State */}
      {!isVideoLoaded && (
        <div className="loading-spinner">
          <div className="spinner" />
        </div>
      )}

      {/* Video Background */}
      <div className="video-background">
        <div className="video-overlay" /> {/* Dunklerer Overlay */}
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => setIsVideoLoaded(true)}
          className="video-element"
        >
          <img 
            alt="Hero Background" 
            className="video-fallback"
          />
        </video>
      </div>

      {/* Content - Text direkt auf dem Video */}
      <div className="hero-content">
        <h1 className="hero-title" ref={glitch.ref}>
          {title}
        </h1>
        <div className="hero-subtitle">
          <TypeWriter 
            texts={typingTexts}
            speed={100}
            delay={2000}
          />.
        </div>
        
        {/* Scroll Indicator */}
        </div>
        <div className="scroll-indicator"
        onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}

        >
          <svg 
            className="scroll-icon"
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="butt" 
              strokeLinejoin="round" 
              strokeWidth={3}
              d="M19 14l-7 7m0 0l-7-7m7 7V3"
            />
          </svg>
      </div>
    </div>
  );
}; 
