import { GrooveCastItem } from "./components/GooveCast/GrooveCastDisk";

export const grooveCastItems: GrooveCastItem[] = [
  {
    id: 1,
    title: "Groove Cast #1 - Nicola Mingrone | Hardgroove / 145 BPM",
    cover:
      "https://i1.sndcdn.com/artworks-7hx6FXNnUfArjZmv-oqaRWw-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-1-nicola-mingrone?in=avantilabelgroup/sets/groovecast",
    artist: "Nicola Mingrone",
  },
  {
    id: 2,
    title: "Groove Cast #2 - Explicit | Hard Groove / 150 BPM",
    cover:
      "https://i1.sndcdn.com/artworks-i3zQOHq5zoewf1TY-OX6jLw-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-2-explicit?in=avantilabelgroup/sets/groovecast",
    artist: "Explicit",
  },
  {
    id: 3,
    title: "Groove Cast #3 - PHEEL (DE) | Garde Groove / 145 BPM",
    cover:
      "https://i1.sndcdn.com/artworks-9mbVVgaYG7Nvvppa-F2Lxhw-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-3-pheel-de?in=avantilabelgroup/sets/groovecast",
    artist: "PHEEL (DE)",
  },
  {
    id: 4,
    title: "Groove Cast #4 - Jui | Groove, Hardgroove / BPM 144-147",
    cover:
      "https://i1.sndcdn.com/artworks-2rS5YCOVAB6qfz1b-vYWyzw-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-4-jui?in=avantilabelgroup/sets/groovecast",
    artist: "Jui",
  },
  {
    id: 5,
    title: "Groove Cast #5 - Sloli | Hard Groove, Trance / 142 - 144 BPM",
    cover:
      "https://i1.sndcdn.com/artworks-U74Ky3qXYv2oRS6p-nrBQuQ-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-5-sloli?in=avantilabelgroup/sets/groovecast",
    artist: "Sloli",
  },
  {
    id: 6,
    title: "Groove Cast #6 - R.D.J | Peak Time Groove / 137-149 BPM",
    cover:
      "https://i1.sndcdn.com/artworks-jLF2bHlD6TICCaQT-jwr74Q-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-6-rdj?in=avantilabelgroup/sets/groovecast",
    artist: "R.D.J",
  },
  {
    id: 7,
    title: "Groove Cast #7 - Morpheus | Hardgroove, Groove / BPM 140-145",
    cover:
      "https://i1.sndcdn.com/artworks-IB2NuVw8gFFpPGZc-0gZCqQ-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-morpheus?in=avantilabelgroup/sets/groovecast",
    artist: "Morpheus",
  },
  {
    id: 8,
    title: "Groove Cast #8 - Esch | Bouncy, Groove, Hard Kicks / 150-165 BPM",
    cover:
      "https://i1.sndcdn.com/artworks-xG0zazsvz3IKCpb4-Q9QE9w-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-7-esch?in=avantilabelgroup/sets/groovecast",
    artist: "Esch",
  },
  {
    id: 9,
    title: "Groove Cast #9 - D.B.R | Hard Groove / 148 BPM",
    cover:
      "https://i1.sndcdn.com/artworks-FGXM4meFvSFNfvUW-yuZqlw-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-9-dbr-hard-groove-148-bpm?in=avantilabelgroup/sets/groovecast",
    artist: "D.B.R",
  },
  {
    id: 10,
    title: "Groove Cast #10 - Steek | Hardgroove / 144-147 BPM",
    cover:
      "https://i1.sndcdn.com/artworks-A1dxxgreKzIH23qi-41lFHg-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-10-steek?in=avantilabelgroup/sets/groovecast",
    artist: "Steek",
  },
  {
    id: 11,
    title:
      "Groove Cast #11 - LEVI AMBS | Oldschool Techno , 2000s Hardgroove / 144 BPM",
    cover:
      "https://i1.sndcdn.com/artworks-MagvbBUzv7NhT43J-CDLIyA-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-10-levi-ambs?in=avantilabelgroup/sets/groovecast",
    artist: "Levi AMBS",
  },
  {
    id: 12,
    title:
      "Groove Cast #12 LIVE - Zona Interdunar | Industrial Groove / 150 BPM",
    cover:
      "https://i1.sndcdn.com/artworks-gnUziTlfi3SJgKEw-seUcvw-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-11-zona-interdunar?in=avantilabelgroup/sets/groovecast",
    artist: "Zona Interdunar",
  },
  {
    id: 13,
    title: "Groove Cast #15 - Deschlorø | 150-160 bpm /",
    cover:
      "https://i1.sndcdn.com/artworks-9yCdlmyFYpyQG3xr-yDUTiA-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-11-deschloro-140-155-bpm?in=avantilabelgroup/sets/groovecast",
    artist: "Deschlorø",
  },
  {
    id: 14,
    title:
      "Groove Cast #16 - Dyshell Comlexierence | Raw Deep Hypnotic / 134-139.99 bpm",
    cover:
      "https://i1.sndcdn.com/artworks-yLu0KCUV0mBFEC10-6mBEmQ-t250x250.jpg",
    soundcloud:
      "https://soundcloud.com/avantilabelgroup/groove-cast-dyshell?in=avantilabelgroup/sets/groovecast",
    artist: "Dyshell",
  },
];
