import React, { useEffect, useState } from "react";
import "./GrooveCastIII.css";

interface GroveCastObject {
  soundcloudUrl: string;
  imageUrl: string;
}

const fetchGrooveCastIII = async (): Promise<GroveCastObject[]> => {
  const response = await fetch("https://api.event-scanner.com/groovecast/3");
  const data = await response.json();
  console.log(data);
  return data.reverse();
};

const GrooveCastIII: React.FC = () => {
  const [grooveCastObjects, setGrooveCastObjects] = useState<GroveCastObject[]>(
    []
  );

  useEffect(() => {
    const fetchImages = async () => {
      const data = await fetchGrooveCastIII();
      setGrooveCastObjects(data);
    };
    fetchImages();
  }, []);

  return (
    <div className="groove-cast-container">
      <h2 className="groove-title">Groove Cast Season III</h2>
      <div className="groove-scroll">
        {grooveCastObjects.map((item, index) => (
          <div
            key={index}
            className="groove-item"
            onClick={() => window.open(item.soundcloudUrl, "_blank")}
          >
            <img
              src={item.imageUrl}
              alt={`Groove ${index + 1}`}
              className="groove-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GrooveCastIII;
