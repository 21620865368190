import React from 'react';
import styles from './Social.module.css';

const socialLinks = [
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/avantikollektiv/',
    svg: (
      <svg className={styles.icon} viewBox="0 0 24 24" fill="currentColor">
        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
      </svg>
    ),
  },
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/avantikollektiv',
    svg: (
      <svg className={styles.icon} viewBox="0 0 24 24" fill="currentColor">
        <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
      </svg>
    ),
  },
  {
    name: 'WhatsApp',
    url: 'https://chat.whatsapp.com/DUArZSEpHYrDTC9wmtrtOs',
    svg: (
      <svg className={styles.icon} viewBox="0 0 24 24" fill="currentColor">
        <path d="M12 0c6.627 0 12 5.373 12 12 0 2.084-.528 4.026-1.457 5.729l1.315 4.815-5.03-1.293C16.018 22.472 14.069 23 12 23 5.373 23 0 17.627 0 12 0 5.373 5.373 0 12 0zm.75 17.25c3.141 0 5.75-2.609 5.75-5.75s-2.609-5.75-5.75-5.75-5.75 2.609-5.75 5.75c0 .789.153 1.541.439 2.227l-.639 2.341 2.41-.63c.669.269 1.401.412 2.16.412z"/>
      </svg>
    ),
  },
];

const Social: React.FC = () => {
  return (
    <section id="social" className={styles.social}>
      <div className={styles.socialLinks}>
        {socialLinks.map((link) => (
          <a
            key={link.name}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialLink}
          >
            {link.svg}
          </a>
        ))}
      </div>
    </section>
  );
};

export default Social;
