import React from "react";
import "./Residents.css";

const BASE_URL = "https://images.vartakt.com/images/avanti/residents/";

const residents = [
  { id: 5, name: "Casallo", image: `${BASE_URL}581a24a7-acd7-4bfd-9970-4c9efd3c2755.jpg` },
  { id: 1, name: "Cri7al", image: `${BASE_URL}13470cc6-5bcc-4fa2-bc24-4787a0a4e364.jpg` },
  { id: 2, name: "Hidden Force", image: `${BASE_URL}71b3b959-6d76-4519-868d-e4825c397ac9.jpg` },
  { id: 3, name: "Morpheus", image: `${BASE_URL}0e13420b-e729-4dba-9f9b-45d4c23a3df1.jpg` },
  { id: 4, name: "Rizz", image: `${BASE_URL}61ed68d8-81a9-4c5c-9fed-caef4940ef96.jpg` },
];

const handleSubmit = (artist: string) => {
    const subject = encodeURIComponent("Ich moechte den artist booken");
    const body = encodeURIComponent(
      `Hallo Avanti,

      ich moechte den Artist ${artist} buchen. Hier sind die Details:

        Datum: ${new Date().toLocaleDateString()}
`
    );
    window.location.href = `mailto:avantilabelgroup@gmail.com?subject=${subject}&body=${body}`;
};

const Residents: React.FC = () => {
  return (
    <div className="residents-container">
      {residents.map((artist) => (
        <div key={artist.id} className="resident-card">
          <img src={artist.image} alt={artist.name} className="resident-image" />
          <div className="hover-overlay">
            <button
              className="book-button"
              onClick={() => handleSubmit(artist.name)}
            >
              BOOK
            </button>
          </div>
          <p className="resident-name">{artist.name}</p>
        </div>
      ))}
    </div>
  );
};

export default Residents;
