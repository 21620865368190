import { Event } from '../types';

interface ApiEvent {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  startDate: string;
  startTime?: string;
  location?: string;
  ticketLink?: string;
  link?: string;
}

interface ApiResponse {
  events: ApiEvent[];
  meta: {
    total: number;
    page: number;
    limit: number;
    pages: number;
  };
}

const transformApiEvent = (apiEvent: ApiEvent): Event => {
  return {
    id: apiEvent.id,
    title: apiEvent.title,
    date: new Date(apiEvent.startDate).toISOString().split('T')[0],
    location: apiEvent.location || 'TBA',
    description: apiEvent.description,
    image: apiEvent.imageUrl,
    link: apiEvent.link || 'https://www.instagram.com/avantikollektiv/',
    ticketLink: apiEvent.ticketLink || "https://avantikollektiv.ticket.io/",
  };
};

export const fetchEvents = async (): Promise<{
  upcomingEvents: Event[];
  pastEvents: Event[];
}> => {
  try {
    const response = await fetch('https://api.event-scanner.com/events/host/id/67c1d2f2441603d892718dc0', {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: ApiResponse = await response.json();
    const now = new Date();
    const events = data.events.map(transformApiEvent);

    const upcomingEvents = events
      .filter(event => new Date(event.date) >= now)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    const pastEvents = events
      .filter(event => new Date(event.date) < now)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    return { upcomingEvents, pastEvents };
  } catch (error) {
    console.error('Error fetching events:', error);
    // Return empty arrays if there's an error
    return { upcomingEvents: [], pastEvents: [] };
  }
}; 