import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/logo.svg";

const Menu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <>
      <Nav>
        <BurgerButton onClick={toggleMenu}>
          <div />
          <div />
          <div />
        </BurgerButton>
        <Logo src={logo} alt="Avanti Logo" onClick={() => (window.location.href = "/")} />
        <NavLinks open={menuOpen}>
          <NavItem to="/" onClick={toggleMenu}>
            Home
          </NavItem>
          <NavItem to="/events" onClick={toggleMenu}>
            Events
          </NavItem>
          <NavItem to="/residents" onClick={toggleMenu}>
            Residents
          </NavItem>
          <NavItem to="/vermietung" onClick={toggleMenu}>
            Vermietung
          </NavItem>
          <NavItem to="/shop" onClick={toggleMenu}>
            Shop
          </NavItem>
          <NavItem to="/groovecast" onClick={toggleMenu}>
            Groovecast
          </NavItem>
        </NavLinks>
      </Nav>
    </>
  );
};

export default Menu;

const Nav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  padding: 1rem;
  z-index: 1000;
`;

const Logo = styled.img`
  height:7rem; /* Erhöht die Höhe */
  width: auto; /* Behält das Seitenverhältnis bei */
  margin: auto;
  filter: drop-shadow(2px 2px 5px rgba(255, 255, 255, 0.5));
  @media (max-width: 768px) {
   height: 60px;
   margin-left: 30%;
  }
`;


const BurgerButton = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1100;
  display: flex;

  div {
    width: 2rem;
    height: 0.2rem;
    background: white;
    border-radius: 10px;
    transition: all 0.3s linear;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const NavLinks = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-100%)")};
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    padding-top: 2rem;
`;

const NavItem = styled(Link)`
  color: white;
  margin: 1rem 0;
  text-decoration: none;
  font-size: 1.5rem;
  text-align: center;

  &:hover {
    color: purple;
  }
`;
