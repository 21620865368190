
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import axios from 'axios';

interface FormData {
  soundcloudUrl: string;
  season: number;
  image: File | null;
}

export const UploadGrooveCast: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    soundcloudUrl: '',
    season: 1,
    image: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState<string>('');

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setFormData(prev => ({ ...prev, image: file }));
    setPreview(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 1,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
        const formDataToSend = new FormData();
        formDataToSend.append('soundcloudUrl', formData.soundcloudUrl);
        formDataToSend.append('season', formData.season.toString());

        // Image nur anhängen, wenn es existiert und vom richtigen Typ ist
        if (formData.image && formData.image instanceof File) {
            formDataToSend.append('image', formData.image);
        }

        // Kein manuelles Setzen von Content-Type
        await axios.post('https://api.event-scanner.com/groovecast', formDataToSend);

        console.log('Upload successful! 🎉');
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Server responded with:', error.response.data);
            console.log('Status:', error.response.status);
        } else {
            console.error('Upload failed:', error);
        }
        console.log('Upload failed! 😢');
    } finally {
        setIsLoading(false);
    }
};


  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <DropzoneContainer {...getRootProps()} isDragActive={isDragActive}>
          <input {...getInputProps()} />
          {preview ? (
            <PreviewImage src={preview} alt="Preview" />
          ) : (
            <p>🖼️ Drag & drop an image here, or click to select</p>
          )}
        </DropzoneContainer>

        <FormGroup>
          <label>Season:</label>
          <Select
            value={formData.season}
            onChange={(e) => setFormData(prev => ({ ...prev, season: parseInt(e.target.value) }))}
          >
            {[1, 2, 3].map(num => (
              <option key={num} value={num}>Season {num}</option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup>
          <label>Soundcloud URL:</label>
          <Input
            type="url"
            value={formData.soundcloudUrl}
            onChange={(e) => setFormData(prev => ({ ...prev, soundcloudUrl: e.target.value }))}
            placeholder="https://soundcloud.com/your-track"
            required
          />
        </FormGroup>

        <SubmitButton type="submit" disabled={isLoading}>
          {isLoading ? '📤 Uploading...' : '📤 Upload'}
        </SubmitButton>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const DropzoneContainer = styled.div<{ isDragActive: boolean }>`
  border: 2px dashed ${props => props.isDragActive ? '#4a90e2' : '#ccc'};
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: border 0.3s ease;
  
  &:hover {
    border-color: #4a90e2;
  }
`;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const Select = styled.select`
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const SubmitButton = styled.button`
  padding: 1rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #357abd;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;