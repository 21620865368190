import React from "react";
import "./GrooveCastII.css";

export interface GrooveCastItem {
  id: number;
  title: string;
  cover: string;
  soundcloud: string;
  name?: string;
  artist?: string;
}

const GrooveCastDisks: React.FC<{ grooveCasts: GrooveCastItem[] }> = ({
  grooveCasts,
}) => {
  return (
    <div className="groovecastII-container">
      <h2 className="groove-title">Groove Cast Season I</h2>
      <div className="groovecast-grid">
        {grooveCasts.map((cast) => (
          <div
            key={cast.id}
            className="groovecast-item"
            onClick={() => window.open(cast.soundcloud, "_blank")}
          >
            <div className="groovecast-cover">
              <img
                src={`${cast.cover}`}
                alt={cast.title}
                className="cover-image"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GrooveCastDisks;
